<template>
  <v-app>
    <v-main>
      <template v-if="user !== null && !isLoading">
        <template>
          <div class="campaign-rewards-creators-web">
            <div class="bg-main"></div>
            <div class="bg-open"></div>
            <div class="content">
              <div class="header">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo" src="/imgs/twitch-logo.png" /></a>
                <div class="creator-photo">
                  <div class="photo">
                    <v-tooltip bottom color="#9146ff" v-if="user !== null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar style="cursor: pointer" @click="logout" class="ml-2" v-bind="attrs" v-on="on">
                          <v-img :src="user?.profile_image_url"></v-img>
                        </v-avatar>
                      </template>
                      <span class="logout">{{ $vuetify.lang.t('$vuetify.logout') }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <template v-if="user !== null">
                  <div class="creator-username" v-text="user?.display_name"></div>
                </template>
              </div>
              <div class="back-container" @click="viewSettings">
                <svg class="back-button" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#B2E816" />
                  <path d="M38 21L27 32L38 43" stroke="black" stroke-width="6" stroke-linecap="round" />
                </svg>
              </div>
              <div class="welcome">{{ viewChannelsCampaign?.name }}{{ ' Channels' }}</div>
              <div class="search-box">
                <div class="search-rewards">Search Channel</div>
                <div class="search-bg">
                  <input type="text" class="search-text" v-model="couponsSearch" />
                </div>
                <svg class="search-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.7883 21.9095C18.0354 23.2224 15.8585 24 13.5 24C7.70101 24 3 19.299 3 13.5C3 7.70101 7.70101 3 13.5 3C19.299 3 24 7.70101 24 13.5C24 15.8585 23.2224 18.0353 21.9096 19.7882L26.5608 24.4394L24.4395 26.5607L19.7883 21.9095ZM21 13.5C21 17.6421 17.6421 21 13.5 21C9.35786 21 6 17.6421 6 13.5C6 9.35786 9.35786 6 13.5 6C17.6421 6 21 9.35786 21 13.5Z"
                    fill="#D2D2E6"
                  />
                </svg>
              </div>
              <div class="rewards-page-box">
                <div class="rewards-per-page">Channels per Page</div>
                <div class="page-bg">
                  <input type="number" class="page-text" v-model="pageSize" min="1" max="10" onKeyUp="if(this.value>10){this.value=10;}else if(this.value<0){this.value=0;}" />
                </div>
              </div>
              <template v-if="user.role === 'super'">
                <div @click="add">
                  <div class="approve-button" :style="'top: ' + (1 * 130 + 296) + 'px !important'">
                    <div class="approve-text">Add Bulk Bonus Score</div>
                  </div>
                </div>
              </template>
              <div class="rewards-table">
                <template v-if="pageItems && pageItems.length === 0 ? true : false">
                  <div class="rewards-no-data">No channels available</div>
                </template>
                <template v-else>
                  <div>
                    <div class="display-name">Display Name</div>
                    <div class="level-completed">Level Completed</div>
                    <div class="current-score">Current Score</div>
                    <div class="overlay-used">Overlay Used</div>
                    <div class="logged-in">Logged In</div>
                    <div class="last-login">Last Login</div>
                    <div class="partner-or-affiliate">Partner or Affiliate</div>
                  </div>
                  <template v-for="(item, index) in pageItems">
                    <div :key="index">
                      <div :class="'row-bg-' + index"></div>
                      <div class="row-photo" :style="'top: ' + ((index + 1) * 130 + 472) + 'px !important'">
                        <v-avatar size="78">
                          <img alt="user" :src="item?.channel?.profile_image_url" />
                        </v-avatar>
                        <template v-if="item.channel.broadcaster_type == 'partner'">
                          <svg class="partner-logo" type="color-fill-current" width="28px" height="28px" version="1.1" viewBox="0 0 16 16" x="0px" y="0px">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 3.5L8 2L3.5 3.5L2 8L3.5 12.5L8 14L12.5 12.5L14 8L12.5 3.5ZM7.00008 11L11.5 6.5L10 5L7.00008 8L5.5 6.5L4 8L7.00008 11Z"></path>
                          </svg>
                        </template>
                      </div>
                      <template v-if="item?.channel?.display_name?.length > 13">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="text-truncate row-display-name" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'" v-bind="attrs" v-on="on">{{ item?.channel?.display_name }}</div>
                          </template>
                          <span class="logout">{{ item?.channel?.display_name }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div class="row-display-name" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ item?.channel?.display_name }}</div>
                      </template>
                      <template v-if="item.name?.length > 18">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div :class="'text-truncate row-name-' + index" style="max-width: 250px" v-bind="attrs" v-on="on">{{ item?.name }}</div>
                          </template>
                          <span class="logout">{{ item?.name }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div :class="'text-truncate row-name-' + index" style="max-width: 250px">{{ item?.name }}</div>
                      </template>
                      <div class="row-level-completed" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ getCompletedTier(item.score, viewChannelsCampaign.tiers) }}</div>
                      <div class="row-current-score" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">
                        <v-hover>
                          <template v-slot="{ hover }">
                            <div>
                              {{ item.score }}
                              <v-fade-transition>
                                <v-overlay class="score-overlay" v-if="hover" absolute color="#036358" style="z-index: 20 !important; position: relative; top: -250px; background-color: unset !important">
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-card width="340px">
                                          <v-card-text>
                                            <p>
                                              <b>{{ item?.channel?.display_name }} - Score Breakdown</b>
                                            </p>
                                            <table>
                                              <tr>
                                                <th></th>
                                                <th style="text-align: left">T1</th>
                                                <th style="text-align: left">T2</th>
                                                <th style="text-align: left">T3</th>
                                              </tr>
                                              <tr>
                                                <td>Subs</td>
                                                <td>{{ item.t1_subs }}</td>
                                                <td>{{ item.t2_subs }}</td>
                                                <td>{{ item.t3_subs }}</td>
                                              </tr>
                                              <tr>
                                                <td>Resubs</td>
                                                <td>{{ item.t1_resub }}</td>
                                                <td>{{ item.t2_resub }}</td>
                                                <td>{{ item.t3_resub }}</td>
                                              </tr>
                                              <tr>
                                                <td>Gift Subs</td>
                                                <td>{{ item.t1_gift_subs }}</td>
                                                <td>{{ item.t2_gift_subs }}</td>
                                                <td>{{ item.t3_gift_subs }}</td>
                                              </tr>
                                              <tr>
                                                <th style="text-align: left"></th>
                                                <th style="text-align: left">Total</th>
                                                <th></th>
                                                <th></th>
                                              </tr>
                                              <tr>
                                                <td>Bits</td>
                                                <td>{{ item.bits }}</td>
                                              </tr>
                                              <tr>
                                                <td>Raids</td>
                                                <td>{{ item.raiders }}</td>
                                              </tr>
                                              <tr>
                                                <td>Bonus EXP</td>
                                                <td colspan="3">
                                                  <v-text-field v-model="item.bonus_e_x_p" type="number" label="">
                                                    <template v-slot:append-outer>
                                                      <v-btn
                                                        color="primary"
                                                        block
                                                        @click="
                                                          updateBonusScore({
                                                            scoreId: item.id,
                                                            score: item.bonus_e_x_p
                                                          })
                                                        "
                                                      >
                                                        Update
                                                      </v-btn>
                                                    </template>
                                                  </v-text-field>
                                                </td>
                                              </tr>
                                            </table>
                                          </v-card-text>
                                          <v-card-actions style="margin-top: -30px">
                                            <v-btn
                                              v-if="campaignStarted && !campaignExpired"
                                              :color="!item.blocked ? 'warning' : 'success'"
                                              block
                                              @click="
                                                blockChannel({
                                                  scoreId: item.id,
                                                  block: !item.blocked
                                                })
                                              "
                                            >
                                              {{ item.blocked ? 'Unblock' : 'Block' }} Channel
                                            </v-btn>
                                            <v-btn
                                              v-if="!campaignStarted"
                                              color="error"
                                              block
                                              @click="
                                                deleteChannel({
                                                  campaignId: viewChannelsCampaign.id,
                                                  scoreId: item.channel.id
                                                })
                                              "
                                            >
                                              Delete Channel
                                            </v-btn>
                                          </v-card-actions>
                                          <v-card-actions v-if="user?.role === 'super'">
                                            <v-btn color="warning" block @click="downloadLogs(item.channel.id)"> Export Events Logs </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-overlay>
                              </v-fade-transition>
                            </div>
                          </template>
                        </v-hover>
                      </div>
                      <div class="row-overlay-used" :style="'top: ' + ((index + 1) * 130 + 482) + 'px !important'">
                        <template v-if="item.overlay_used">
                          <svg class="check" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 27.5L24.75 41.25L46.75 19.25L42.625 15.125L24.75 33L15.125 23.375L11 27.5Z" fill="#BEFF00" />
                          </svg>
                        </template>
                        <template v-else>
                          <svg class="hide-close" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.375 27.5L11 15.125L15.125 11L27.5 23.375L39.875 11L44 15.125L31.625 27.5L44 39.875L39.875 44L27.5 31.625L15.125 44L11 39.875L23.375 27.5Z" fill="#BB1411" />
                          </svg>
                        </template>
                      </div>
                      <div class="row-logged-in" :style="'top: ' + ((index + 1) * 130 + 482) + 'px !important'">
                        <template v-if="item.channel_info.logged_in">
                          <svg class="check" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 27.5L24.75 41.25L46.75 19.25L42.625 15.125L24.75 33L15.125 23.375L11 27.5Z" fill="#BEFF00" />
                          </svg>
                        </template>
                        <template v-else>
                          <svg class="hide-close" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.375 27.5L11 15.125L15.125 11L27.5 23.375L39.875 11L44 15.125L31.625 27.5L44 39.875L39.875 44L27.5 31.625L15.125 44L11 39.875L23.375 27.5Z" fill="#BB1411" />
                          </svg>
                        </template>
                      </div>
                      <div class="row-last-login" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ item.channel_info.logged_in ? new Date(item.channel_info.last_login).toLocaleString() : '' }}</div>
                      <div class="row-partner-or-affiliate" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ item.channel.broadcaster_type?.toUpperCase().charAt(0) || 'X' }}</div>
                    </div>
                  </template>
                  <div @click="downloadChannels">
                    <div class="download-button" :style="'top: ' + ((pageItems.length || 1) * 130 + 620) + 'px !important'">
                      <div class="download-text">Download Full Data</div>
                    </div>
                  </div>
                  <div class="pagination" :style="'top: ' + ((pageItems.length || 1) * 130 + 660) + 'px !important'">
                    <v-pagination v-model="page" :length="pages" color="#424242"></v-pagination>
                  </div>
                </template>
              </div>
              <img class="red-bits-rewards" src="/imgs/red-bits-rewards.png" />
              <div class="no-mobile-view">Mobile Version Not Supported:<br />Please access the desktop version of GlitchEXP to continue</div>
              <div class="footer">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
                <div>
                  <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
                </div>
                <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
                <div>
                  <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
                </div>
                <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
              </div>
            </div>
          </div>
        </template>
        <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="addNew" opacity="1">
          <v-card>
            <v-toolbar>
              <v-toolbar-title> Add Bulk Bonus Score</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="error" rounded @click="cancel"> Cancel </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="9">
                    <v-textarea v-model="importBonus" label="Bulk Bonus"></v-textarea>
                  </v-col>
                  <v-col cols="3">
                    <v-btn color="primary" rounded @click="doImportBonus"> Bulk Bonus </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar top color="green" v-model="success" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>Scores {{ action }}ed successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="success = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="error" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while {{ action }}ing the scores. Please check your data and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="error = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
      </template>
      <template v-else>
        <v-overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sortBy } from 'lodash'

export default {
  data() {
    return {
      campaignTabs: 0,
      page: 1,
      pageSize: 10,
      showSearch: '',
      showLoggedIn: true,
      showNotLoggedIn: true,
      showOverlayUsed: true,
      showOverlayNotUsed: true,
      showMenu: false,
      policy: false,
      viewChannelsDialog: false,
      viewChannelsCampaign: null,
      newChannelDialog: false,
      newChannelCampaign: null,
      newChannelSearch: '',
      importText: '',
      isLoading: true,
      isLoadingCoupons: true,
      isSearch: true,
      overlay: false,
      startDate: Date.now(),
      endDate: Date.now(),
      startTime: null,
      endTime: null,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      name: '',
      includeSubs: true,
      includeBits: true,
      includeRaids: false,
      raidTimezone: '',
      raidTag: '',
      raidLevel: '',
      tier1: 0,
      tier2: 0,
      tier3: 0,
      tier4: 0,
      tier5: 0,
      tier6: 0,
      tier7: 0,
      tier8: 0,
      tier9: 0,
      tier10: 0,
      tier11: 0,
      tier12: 0,
      rules: [(v) => v < 2147483647 || 'Max should not be above 2,147,483,647'],
      link: process.env.VUE_APP_BASE_API_URL,
      raidLevels: [
        { name: 'Partner only', value: 'partner' },
        { name: 'Partner & Affiliate only', value: 'affiliate' },
        { name: 'All', value: 'all' }
      ],
      languages: [
        { name: 'Abkhazian', value: 'ab' },
        { name: 'Afar', value: 'aa' },
        { name: 'Afrikaans', value: 'af' },
        { name: 'Akan', value: 'ak' },
        { name: 'Albanian', value: 'sq' },
        { name: 'Amharic', value: 'am' },
        { name: 'Arabic', value: 'ar' },
        { name: 'Aragonese', value: 'an' },
        { name: 'Armenian', value: 'hy' },
        { name: 'Assamese', value: 'as' },
        { name: 'Avaric', value: 'av' },
        { name: 'Avestan', value: 'ae' },
        { name: 'Aymara', value: 'ay' },
        { name: 'Azerbaijani', value: 'az' },
        { name: 'Bambara', value: 'bm' },
        { name: 'Bashkir', value: 'ba' },
        { name: 'Basque', value: 'eu' },
        { name: 'Belarusian', value: 'be' },
        { name: 'Bengali', value: 'bn' },
        { name: 'Bislama', value: 'bi' },
        { name: 'Bosnian', value: 'bs' },
        { name: 'Breton', value: 'br' },
        { name: 'Bulgarian', value: 'bg' },
        { name: 'Burmese', value: 'my' },
        { name: 'Catalan, Valencian', value: 'ca' },
        { name: 'Chamorro', value: 'ch' },
        { name: 'Chechen', value: 'ce' },
        { name: 'Chichewa, Chewa, Nyanja', value: 'ny' },
        { name: 'Chinese', value: 'zh' },
        {
          name: 'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
          value: 'cu'
        },
        { name: 'Chuvash', value: 'cv' },
        { name: 'Cornish', value: 'kw' },
        { name: 'Corsican', value: 'co' },
        { name: 'Cree', value: 'cr' },
        { name: 'Croatian', value: 'hr' },
        { name: 'Czech', value: 'cs' },
        { name: 'Danish', value: 'da' },
        { name: 'Divehi, Dhivehi, Maldivian', value: 'dv' },
        { name: 'Dutch, Flemish', value: 'nl' },
        { name: 'Dzongkha', value: 'dz' },
        { name: 'English', value: 'en' },
        { name: 'Esperanto', value: 'eo' },
        { name: 'Estonian', value: 'et' },
        { name: 'Ewe', value: 'ee' },
        { name: 'Faroese', value: 'fo' },
        { name: 'Fijian', value: 'fj' },
        { name: 'Finnish', value: 'fi' },
        { name: 'French', value: 'fr' },
        { name: 'Western Frisian', value: 'fy' },
        { name: 'Fulah', value: 'ff' },
        { name: 'Gaelic, Scottish Gaelic', value: 'gd' },
        { name: 'Galician', value: 'gl' },
        { name: 'Ganda', value: 'lg' },
        { name: 'Georgian', value: 'ka' },
        { name: 'German', value: 'de' },
        { name: 'Greek, Modern (1453–)', value: 'el' },
        { name: 'Kalaallisut, Greenlandic', value: 'kl' },
        { name: 'Guarani', value: 'gn' },
        { name: 'Gujarati', value: 'gu' },
        { name: 'Haitian, Haitian Creole', value: 'ht' },
        { name: 'Hausa', value: 'ha' },
        { name: 'Hebrew', value: 'he' },
        { name: 'Herero', value: 'hz' },
        { name: 'Hindi', value: 'hi' },
        { name: 'Hiri Motu', value: 'ho' },
        { name: 'Hungarian', value: 'hu' },
        { name: 'Icelandic', value: 'is' },
        { name: 'Ido', value: 'io' },
        { name: 'Igbo', value: 'ig' },
        { name: 'Indonesian', value: 'id' },
        {
          name: 'Interlingua (International Auxiliary Language Association)',
          value: 'ia'
        },
        { name: 'Interlingue, Occidental', value: 'ie' },
        { name: 'Inuktitut', value: 'iu' },
        { name: 'Inupiaq', value: 'ik' },
        { name: 'Irish', value: 'ga' },
        { name: 'Italian', value: 'it' },
        { name: 'Japanese', value: 'ja' },
        { name: 'Javanese', value: 'jv' },
        { name: 'Kannada', value: 'kn' },
        { name: 'Kanuri', value: 'kr' },
        { name: 'Kashmiri', value: 'ks' },
        { name: 'Kazakh', value: 'kk' },
        { name: 'Central Khmer', value: 'km' },
        { name: 'Kikuyu, Gikuyu', value: 'ki' },
        { name: 'Kinyarwanda', value: 'rw' },
        { name: 'Kirghiz, Kyrgyz', value: 'ky' },
        { name: 'Komi', value: 'kv' },
        { name: 'Kongo', value: 'kg' },
        { name: 'Korean', value: 'ko' },
        { name: 'Kuanyama, Kwanyama', value: 'kj' },
        { name: 'Kurdish', value: 'ku' },
        { name: 'Lao', value: 'lo' },
        { name: 'Latin', value: 'la' },
        { name: 'Latvian', value: 'lv' },
        { name: 'Limburgan, Limburger, Limburgish', value: 'li' },
        { name: 'Lingala', value: 'ln' },
        { name: 'Lithuanian', value: 'lt' },
        { name: 'Luba-Katanga', value: 'lu' },
        { name: 'Luxembourgish, Letzeburgesch', value: 'lb' },
        { name: 'Macedonian', value: 'mk' },
        { name: 'Malagasy', value: 'mg' },
        { name: 'Malay', value: 'ms' },
        { name: 'Malayalam', value: 'ml' },
        { name: 'Maltese', value: 'mt' },
        { name: 'Manx', value: 'gv' },
        { name: 'Maori', value: 'mi' },
        { name: 'Marathi', value: 'mr' },
        { name: 'Marshallese', value: 'mh' },
        { name: 'Mongolian', value: 'mn' },
        { name: 'Nauru', value: 'na' },
        { name: 'Navajo, Navaho', value: 'nv' },
        { name: 'North Ndebele', value: 'nd' },
        { name: 'South Ndebele', value: 'nr' },
        { name: 'Ndonga', value: 'ng' },
        { name: 'Nepali', value: 'ne' },
        { name: 'Norwegian', value: 'no' },
        { name: 'Norwegian Bokmål', value: 'nb' },
        { name: 'Norwegian Nynorsk', value: 'nn' },
        { name: 'Sichuan Yi, Nuosu', value: 'ii' },
        { name: 'Occitan', value: 'oc' },
        { name: 'Ojibwa', value: 'oj' },
        { name: 'Oriya', value: 'or' },
        { name: 'Oromo', value: 'om' },
        { name: 'Ossetian, Ossetic', value: 'os' },
        { name: 'Pali', value: 'pi' },
        { name: 'Pashto, Pushto', value: 'ps' },
        { name: 'Persian', value: 'fa' },
        { name: 'Polish', value: 'pl' },
        { name: 'Portuguese', value: 'pt' },
        { name: 'Punjabi, Panjabi', value: 'pa' },
        { name: 'Quechua', value: 'qu' },
        { name: 'Romanian, Moldavian, Moldovan', value: 'ro' },
        { name: 'Romansh', value: 'rm' },
        { name: 'Rundi', value: 'rn' },
        { name: 'Russian', value: 'ru' },
        { name: 'Northern Sami', value: 'se' },
        { name: 'Samoan', value: 'sm' },
        { name: 'Sango', value: 'sg' },
        { name: 'Sanskrit', value: 'sa' },
        { name: 'Sardinian', value: 'sc' },
        { name: 'Serbian', value: 'sr' },
        { name: 'Shona', value: 'sn' },
        { name: 'Sindhi', value: 'sd' },
        { name: 'Sinhala, Sinhalese', value: 'si' },
        { name: 'Slovak', value: 'sk' },
        { name: 'Slovenian', value: 'sl' },
        { name: 'Somali', value: 'so' },
        { name: 'Southern Sotho', value: 'st' },
        { name: 'Spanish, Castilian', value: 'es' },
        { name: 'Sundanese', value: 'su' },
        { name: 'Swahili', value: 'sw' },
        { name: 'Swati', value: 'ss' },
        { name: 'Swedish', value: 'sv' },
        { name: 'Tagalog', value: 'tl' },
        { name: 'Tahitian', value: 'ty' },
        { name: 'Tajik', value: 'tg' },
        { name: 'Tamil', value: 'ta' },
        { name: 'Tatar', value: 'tt' },
        { name: 'Telugu', value: 'te' },
        { name: 'Thai', value: 'th' },
        { name: 'Tibetan', value: 'bo' },
        { name: 'Tigrinya', value: 'ti' },
        { name: 'Tonga (Tonga Islands)', value: 'to' },
        { name: 'Tsonga', value: 'ts' },
        { name: 'Tswana', value: 'tn' },
        { name: 'Turkish', value: 'tr' },
        { name: 'Turkmen', value: 'tk' },
        { name: 'Twi', value: 'tw' },
        { name: 'Uighur, Uyghur', value: 'ug' },
        { name: 'Ukrainian', value: 'uk' },
        { name: 'Urdu', value: 'ur' },
        { name: 'Uzbek', value: 'uz' },
        { name: 'Venda', value: 've' },
        { name: 'Vietnamese', value: 'vi' },
        { name: 'Volapük', value: 'vo' },
        { name: 'Walloon', value: 'wa' },
        { name: 'Welsh', value: 'cy' },
        { name: 'Wolof', value: 'wo' },
        { name: 'Xhosa', value: 'xh' },
        { name: 'Yiddish', value: 'yi' },
        { name: 'Yoruba', value: 'yo' },
        { name: 'Zhuang, Chuang', value: 'za' },
        { name: 'Zulu', value: 'zu' }
      ],
      coupons: [],
      couponsSearch: '',
      couponsPage: 1,
      couponsPerPage: 10,
      couponsPageCount: 0,
      couponSuccess: false,
      couponsDialogDelete: false,
      couponsHeaders: [
        { text: 'Coupon Code', value: 'code', align: 'start' },
        { text: 'User ID', value: 'user_id', align: 'start' },
        { text: 'User Name', value: 'user_name' },
        { text: 'Digital Reward Name', value: 'name' },
        { text: 'Digital Reward Mechanics', value: 'mechanics' },
        { text: 'Tier', value: 'tier_level' },
        { text: 'Send (Live/Post)', value: 'live' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null
      },
      addNew: false,
      success: false,
      error: false,
      action: 'add',
      importBonus: ''
    }
  },
  watch: {
    includeRaids(newValue) {
      this.includeSubs = !newValue
      this.includeBits = !newValue
    },
    showOverlayUsed() {
      this.page = 1
    },
    showOverlayNotUsed() {
      this.page = 1
    },
    showLoggedIn() {
      this.page = 1
    },
    showNotLoggedIn() {
      this.page = 1
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    couponsPerPage(newValue) {
      this.couponsPage = 1
    }
  },
  computed: {
    ...mapGetters(['user', 'scores', 'openCampaigns', 'closedCampaigns', 'searchResults', 'staff', 'timezones', 'tags']),
    pages() {
      return this.itemCount < this.pageSize ? 1 : Math.ceil(this.itemCount / this.pageSize)
    },
    pageItems() {
      const items = this.items?.slice((this.page - 1) * this.pageSize, this.page * this.pageSize) ?? []
      return items
    },
    itemCount() {
      return this.items?.length ?? 0
    },
    items() {
      const filtered = this.viewChannelsCampaign?.scores ?? []
      return filtered.filter((item) => {
        let returnItem = true
        if (this.couponsSearch.length > 0) {
          returnItem = item?.channel?.display_name.toLowerCase()?.includes(this.couponsSearch.toLowerCase()) === true
        }
        if (!this.showLoggedIn && item.channel_info.logged_in) {
          returnItem = false
        }
        if (!this.showNotLoggedIn && !item.channel_info.logged_in) {
          returnItem = false
        }
        if (!this.showOverlayUsed && item.overlay_used) returnItem = false
        if (!this.showOverlayNotUsed && !item.overlay_used) returnItem = false
        return returnItem
      })
    },
    campaignStarted() {
      return new Date() > new Date(this.viewChannelsCampaign.start_date)
    },
    campaignExpired() {
      return new Date() > new Date(this.viewChannelsCampaign.end_date)
    },
    overlayUsedCount() {
      let count = 0
      const scores = this.viewChannelsCampaign?.scores ?? []
      scores.forEach((score) => {
        if (score?.overlay_used) {
          count++
        }
      })
      return count
    },
    loggedInCount() {
      let count = 0
      const scores = this.viewChannelsCampaign?.scores ?? []
      scores.forEach((score) => {
        if (score?.channel_info?.logged_in) {
          count++
        }
      })
      return count
    },
    filteredCoupons() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.slice((this.couponsPage - 1) * this.couponsPerPage, this.couponsPage * this.couponsPerPage) ?? 0
    },
    numberOfPages() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.length < this.couponsPerPage ? 1 : Math.ceil(sorted.length / this.couponsPerPage)
    }
  },
  methods: {
    ...mapActions(['loadSystem', 'getCampaignCoupons', 'getCampaign', 'addChannel', 'addChannels', 'deleteChannelScore', 'deleteCoupon', 'search', 'signOut', 'updateBonusExp', 'blockScore', 'updateBonusScores']),
    viewSettings() {
      this.$router.push({ name: 'settings' })
    },
    downloadChannels() {
      window.open(`${this.link}api/Campaign/Export/${this.viewChannelsCampaign?.id}/${this.user?.id}`)
    },
    downloadLogs(channelId) {
      window.open(`${this.link}api/Campaign/Export/Logs/${this.viewChannelsCampaign?.id}/${channelId}/${this.user?.id}`)
    },
    download() {
      window.open(`${this.link}api/Campaign/Export/${this.viewChannelsCampaign?.id}/${this.user?.id}`)
    },
    updateBonusScore(score) {
      this.isLoading = true
      this.updateBonusExp(score).then(() => {
        this.getCampaign(this.$route.params.campaignId).then((result) => {
          this.viewChannelsCampaign = result
          this.isLoading = false
        })
      })
    },
    blockChannel(score) {
      if (confirm(`Are you sure you want to ${score.block ? 'block' : 'unblock'} this channel?`)) {
        this.isLoading = true
        this.blockScore(score).then(() => {
          this.getCampaign(this.$route.params.campaignId).then((result) => {
            this.viewChannelsCampaign = result
            this.isLoading = false
          })
        })
      }
    },
    deleteChannel(score) {
      if (confirm('Are you sure you want to delete this channel?')) {
        this.isLoading = true
        this.deleteChannelScore(score).then(() => {
          this.getCampaign(this.$route.params.campaignId).then((result) => {
            this.viewChannelsCampaign = result
            this.isLoading = false
          })
        })
      }
    },
    getTag(id) {
      const filtered = this.tags.filter((x) => x.id === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return ''
    },
    getLanguage(id) {
      const filtered = this.languages.filter((x) => x.value === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return this.getTag(id)
    },
    getRaidTarget(id) {
      const filtered = this.raidLevels.filter((x) => x.value === id)
      if (filtered.length === 1) {
        return filtered[0].name
      }
      return ''
    },
    logout() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    getCompletedTier(score, tiers) {
      var tierIndex = -1
      for (let i = 0; i < tiers.length; i++) {
        if (tiers[i] <= score) {
          tierIndex = i
        }
      }
      if (tierIndex === -1) return 'N/A'
      return `${tierIndex + 1}`
    },
    getTargetScore(score, tiers) {
      for (let i = 0; i < tiers.length; i++) {
        if (score < tiers[i]) {
          return `${tiers[i]}`
        }
      }
      return 'N/A'
    },
    doSearch() {
      this.search(this.newChannelSearch)
    },
    cancelNewChannel() {
      this.newChannelDialog = false
      this.newChannelCampaign = null
    },
    viewChannels(campaign) {
      this.viewChannelsCampaign = campaign
      this.viewChannelsDialog = true
    },
    newChannel(campaign) {
      this.newChannelCampaign = campaign
      this.newChannelDialog = true
    },
    checkTiers() {
      if (this.tier1 > 2147483646) return false
      if (this.tier2 > 2147483646) return false
      if (this.tier3 > 2147483646) return false
      if (this.tier4 > 2147483646) return false
      if (this.tier5 > 2147483646) return false
      if (this.tier6 > 2147483646) return false
      if (this.tier7 > 2147483646) return false
      if (this.tier8 > 2147483646) return false
      if (this.tier9 > 2147483646) return false
      if (this.tier10 > 2147483646) return false
      if (this.tier11 > 2147483646) return false
      if (this.tier12 > 2147483646) return false
      return true
    },
    addNewChannel(channelId) {
      this.newChannelDialog = false
      this.addChannel({
        campaignId: this.newChannelCampaign.id,
        channelId: channelId
      })
      this.newChannelCampaign = null
    },
    doImport() {
      const finalResult = []
      const result = this.importText.split(/\r?\n/)
      result.forEach((element) => {
        element.split(',').forEach((sub) => {
          finalResult.push(sub.trim())
        })
      })
      this.newChannelDialog = false
      this.addChannels({
        campaignId: this.newChannelCampaign.id,
        channels: finalResult
      })
      this.newChannelCampaign = null
      this.importText = ''
    },
    nextPage() {
      if (this.couponsPage + 1 <= this.numberOfPages) this.couponsPage += 1
    },
    formerPage() {
      if (this.couponsPage - 1 >= 1) this.couponsPage -= 1
    },
    updateCouponsPerPage(number) {
      this.couponsPerPage = number
    },
    deleteItem(item) {
      this.couponSuccess = false
      this.editedIndex = this.coupons.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.couponsDialogDelete = true
    },
    deleteItemConfirm() {
      this.coupons.splice(this.editedIndex, 1)
      this.deleteCoupon(this.editedItem.id)
      this.closeDelete()
      this.couponSuccess = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.couponsDialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    tierAchievementsCount(tierLevel) {
      let count = 0
      const scores = this.viewChannelsCampaign?.scores ?? []
      scores.forEach((score) => {
        var tiersAchieved = []
        this.viewChannelsCampaign.tiers.forEach((tier, i) => {
          if (score?.score >= tier) {
            tiersAchieved.push(i + 1)
          }
        })
        if (tiersAchieved.pop() === tierLevel) {
          count++
        }
      })
      return `Tier ${tierLevel}: ${count}`
    },
    cancel() {
      this.addNew = false
      this.importBonus = ''
    },
    add() {
      this.addNew = true
      this.importBonus = ''
    },
    doImportBonus() {
      this.action = 'update'
      this.isLoading = true
      this.success = false
      this.error = false
      this.addNew = false
      const finalResult = []
      const result = this.importBonus.split(/\r?\n/)
      result.forEach((line) => {
        const data = line.toLowerCase().split(',')
        finalResult.push({
          user_id: data[0],
          bonus: Number(data[1])
        })
      })
      const channels = finalResult.filter((str) => str !== '')
      this.updateBonusScores({
        campaignId: this.viewChannelsCampaign.id,
        scores: channels
      })
        .then((_) => {
          this.success = true
        })
        .catch((_) => {
          this.error = true
        })
        .finally((_) => {
          this.getCampaign(this.viewChannelsCampaign.id).then((result) => {
            this.viewChannelsCampaign = result
            this.isLoading = false
            this.importBonus = ''
            this.cancel()
          })
        })
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.$vuetify.lang.current = localStorage.language ?? 'en'
    if (this.user == null) {
      this.isLoading = true
      this.loadSystem().then((_) => {
        if (this.viewChannelsCampaign == null) {
          this.getCampaign(this.$route.params.campaignId).then((result) => {
            this.viewChannelsCampaign = result
            this.isLoading = false
          })
        } else {
          this.isLoading = false
        }
      })
    } else {
      this.isLoading = true
      if (this.viewChannelsCampaign == null) {
        this.getCampaign(this.$route.params.campaignId).then((result) => {
          this.viewChannelsCampaign = result
          this.isLoading = false
        })
      } else {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>

<style lang="scss" scoped>
.partner-logo {
  color: #bf94ff;
  fill: #bf94ff;
  position: relative;
  left: -20px;
  top: -20px;
}

.rewards {
  margin-top: 12px;
}

.delete {
  margin-left: 10px;
}

.campaign-rewards-creators-web,
.campaign-rewards-creators-web * {
  box-sizing: border-box;
}
.campaign-rewards-creators-web {
  background: #000000 !important;
  width: 100% !important;
  height: 2292px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-main {
  background: linear-gradient(180deg, #e3ff93 0%, #c1ff0c 19.27%);
  width: 100%;
  height: 1074px;
  position: absolute;
  left: 0.46px;
  top: -3px;
  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1103px;
  position: absolute;
  left: 0.46px;
  top: 305px;
  box-shadow: 0px -40px 44px 0px rgba(40, 48, 18, 0.25);
  @media only screen and (max-height: 900px) {
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    width: 395px;
    height: 150px;
    position: absolute;
    left: 0px;
    top: 850px;
  }
}
.header {
  position: absolute;
  inset: 0;
}
.creator-photo {
  width: 42px;
  height: 42px;
  position: static;
}
.photo {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 70px;
  top: 60px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 44px;
    top: 44px;
    z-index: 1;
  }
}
.logout {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-height: 900px) {
    z-index: 1;
  }
}
.creator-username {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  position: absolute;
  right: 130px;
  top: 63px;
  width: 372px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 600 14px/18px 'RoobertRegular', sans-serif;
    position: absolute;
    right: 70px;
    top: 64px;
    width: 124px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.twitch-logo {
  width: 131px;
  height: 44px;
  position: absolute;
  left: 99.46px;
  top: 61px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}
.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-height: 900px) {
    max-height: 900px;
  }
}
.back-container {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.back-container-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.back-button {
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  left: calc(50% - 730px);
  top: 180px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    left: -4px;
    top: 24px;
  }
}
.back-button:hover {
  rect {
    fill: #7d5bbe;
  }
}
.welcome {
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 106px;
  position: absolute;
  left: calc(50% - 623.54px);
  top: 167.4px;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.search-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.search-rewards-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 43px;
  top: 170px;
  width: 168px;
  height: 18px;
}
.search-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 94px;
  height: 20px;
  position: absolute;
  left: 43px;
  top: 191px;
}
.search-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 11px/23.52px 'RoobertRegular', sans-serif;
  margin-left: 20px;
  width: 70px;
  z-index: 100 !important;
  position: absolute;
  top: -3px;
}
.search {
  position: absolute;
  left: 47px;
  top: 194px;
  overflow: visible;
}
.search-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.search-rewards {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 630px);
  top: 388px;
  width: 351px;
}
.search-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 419px;
  height: 50px;
  position: absolute;
  left: calc(50% - 630px);
  top: 427px;
  z-index: 1;
}
.search-icon {
  position: absolute;
  left: calc(50% - 621px);
  top: 437px;
  overflow: visible;
  z-index: 1;
}
.search-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 50px;
  margin-top: -10px;
  width: 350px;
  z-index: 100 !important;
}
.rewards-page-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.rewards-per-page {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 430px);
  top: 388px;
  width: 302px;
}
.page-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 202px;
  height: 50px;
  position: absolute;
  left: calc(50% + 430px);
  top: 427px;
  z-index: 1;
}
.page-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 10px;
  width: 202px;
  height: 47px;
}
.rewards-page-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;

  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.rewards-per-part {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: right;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 185px;
  top: 170px;
  width: 163px;
  height: 19px;
}
.page-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 93px;
  height: 20px;
  position: absolute;
  left: 255px;
  top: 191px;
}
.page-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 12px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 4px;
  top: -4px;
  width: 86px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
}
.download-button {
  box-sizing: border-box;
  background: #beff00;
  border-radius: 326.53px;
  padding: 14px 30px 14px 30px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 480px;
  left: calc(50% - 630px);
  top: 404px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    background: #beff00;
    border-radius: 326.53px;
    padding: 1px 10px 1px 10px;
    display: flex;
    flex-direction: column;
    gap: 42.45px;
    align-items: center;
    justify-content: center;
    height: 25px;
    left: 44px;
    width: 200px;
  }
}
.download-button:hover {
  background: #7d5bbe;
}
.download-text {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 500 14px/42.45px 'RoobertRegular', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer {
  position: absolute;
  inset: 0;
}
.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2042px;

  @media only screen and (max-height: 900px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: unset;
    bottom: 104px !important;
  }
}
.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2190px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2190px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: unset;
    bottom: 36px !important;
    min-width: 200px;
    max-width: 220px;
  }
}
.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2190px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 36px !important;
  }
}
.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2154px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.red-bits-rewards {
  width: 767.91px;
  height: 785px;
  position: absolute;
  right: -300px;
  top: 1560px;
  transform-origin: 0 0;
  transform: rotate(-1.9deg) scale(1, 1);

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
/* ----------------------- */
/*      Rewards Table      */
/* ----------------------- */
.rewards-table {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.pagination {
  position: relative;
  margin: auto;
  width: 50%;
  padding-left: 50px;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    position: absolute;
    left: 76px;
  }
}
.tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 533px;
  width: 169px;
}
.coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 533px;
  width: 269px;
}
.digital-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 533px;
  width: 236px;
}
.digital-reward-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 533px;
  width: 262px;
}
.rewards-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 696px);
  top: 524px;
  width: 675px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.row-bg-0 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 588px;
  z-index: 0;
}
.row-bg-1 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 718px;
  z-index: 0;
}
.row-bg-2 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 848px;
  z-index: 0;
}
.row-bg-3 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 978px;
  z-index: 0;
}
.row-bg-4 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1108px;
  z-index: 0;
}
.row-bg-5 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1238px;
  z-index: 0;
}
.row-bg-6 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1368px;
  z-index: 0;
}
.row-bg-7 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1498px;
  z-index: 0;
}
.row-bg-8 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1628px;
  z-index: 0;
}
.row-bg-9 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1758px;
  z-index: 0;
}
.row-tier-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 624px;
  width: 154px;
}
.row-tier-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 754px;
  width: 185px;
}
.row-tier-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 884px;
  width: 185px;
}
.row-tier-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1014px;
  width: 185px;
}
.row-tier-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1144px;
  width: 185px;
}
.row-tier-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1274px;
  width: 185px;
}
.row-tier-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1404px;
  width: 185px;
}
.row-tier-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1534px;
  width: 185px;
}
.row-tier-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1664px;
  width: 185px;
}
.row-tier-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1794px;
  width: 185px;
}
.row-code-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 624px;
  width: 250px;
  z-index: 1;
}
.row-code-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 754px;
  width: 250px;
  height: 39px;
  z-index: 1;
}
.row-code-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 625px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 754px;
  width: 250px;
  height: 27px;
  z-index: 1;
}
.row-name-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 625px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 754px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 881px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1015px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1145px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1276px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1405px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1535px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1665px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1795px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.rewards-table-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.row-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.row-bg-mobile {
  background: #111111;
  border-radius: 10px;
  width: 306px;
  height: 132px;
  position: absolute;
  left: 43px;
  top: 232px;
}
.row-container-mobile {
  width: 281px;
  height: 97px;
  position: static;
}
.row-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 302px;
  width: 141px;
}
.row-rewards-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 304px;
  width: 127px;
}
.row-tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 253px;
  width: 90px;
  height: 21px;
}
.row-tier-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 272px;
  width: 90px;
  height: 21px;
}
.row-name-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 329px;
  width: 138px;
  height: 21px;
}
.row-coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 224px;
  top: 253px;
  width: 127px;
  height: 22px;
}
.row-code-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 272px;
  width: 120px;
  height: 22px;
}
.row-mechanics-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 324px;
  width: 120px;
  height: 22px;
}

.row-code-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 624px;
  width: 250px;
  z-index: 1;
}
.row-code-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 754px;
  width: 250px;
  height: 39px;
  z-index: 1;
}
.row-code-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}

.display-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 490px);
  top: 533px;
  width: 177px;
  height: 45px;
}
.level-completed {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 240px);
  top: 533px;
  width: 119px;
}
.current-score {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 70px);
  top: 533px;
  width: 149px;
}

.overlay-used {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 533px;
  width: 119px;
}
.logged-in {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 220px);
  top: 533px;
  width: 79px;
}
.last-login {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 340px);
  top: 533px;
  width: 169px;
}
.partner-or-affiliate {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 524px);
  top: 533px;
  width: 169px;
}

.row-photo {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  position: absolute;
  left: calc(50% - 615px);
  top: 602px;
  z-index: 1;
  cursor: pointer;
}

.row-display-name {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 490px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-level-completed {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 240px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}
.row-current-score {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 66px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
  cursor: pointer;
}
.row-overlay-used {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 102px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
}

.row-logged-in {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 214px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-last-login {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 340px);
  top: 622px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-partner-or-affiliate {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 524px);
  top: 622px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.no-mobile-view {
  visibility: hidden;

  @media only screen and (max-height: 900px) {
    visibility: visible;
    color: #000000;
    text-align: center;
    font: 900 23.14px/25.23px 'RoobertRegular', sans-serif;
    position: absolute;
    left: 10px;
    top: 420px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.score-overlay {
  z-index: 999 !important;
}

.approve-button {
  box-sizing: border-box;
  background: #beff00;
  border-radius: 326.53px;
  padding: 14px 30px 14px 30px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 230px;
  height: 50px;
  left: calc(50% + 10px);
  top: 434px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.approve-button:hover {
  background: #7d5bbe;
}

.approve-text {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 17px/21px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 500 14px/42.45px 'RoobertRegular', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
